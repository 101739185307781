/* eslint-disable react/jsx-props-no-spreading */

export function EyeIcon(props) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.814 11.772c-.032-.073-.811-1.803-2.553-3.545-1.617-1.614-4.393-3.54-8.46-3.54-4.067 0-6.844 1.926-8.46 3.54-1.742 1.742-2.522 3.47-2.554 3.545a.562.562 0 0 0 0 .458c.032.072.812 1.802 2.554 3.543 1.616 1.615 4.393 3.54 8.46 3.54 4.066 0 6.843-1.925 8.46-3.54 1.742-1.741 2.521-3.468 2.553-3.543a.563.563 0 0 0 0-.458Zm-11.013 6.415c-2.942 0-5.511-1.07-7.636-3.18A12.636 12.636 0 0 1 2.928 12a12.615 12.615 0 0 1 2.237-3.006C7.29 6.884 9.859 5.813 12.8 5.813s5.51 1.07 7.636 3.181A12.616 12.616 0 0 1 22.673 12c-.597 1.145-3.591 6.188-9.872 6.188Zm0-10.5a4.313 4.313 0 1 0 0 8.626 4.313 4.313 0 0 0 0-8.625Zm0 7.5a3.187 3.187 0 1 1 0-6.373 3.187 3.187 0 0 1 0 6.373Z"
        fill="#303030"
      />
    </svg>
  );
}

export function EyeSlashIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#1E1E1E" d="M0 0h24v24H0z" />
      <path fill="#79A9E0" d="M-1240-512H200V512h-1440z" />
      <path fill="#F8F8F8" d="M-1240 0H200v512h-1440z" />
      <g filter="url(#a)">
        <rect x={-400} y={-336} width={480} height={672} rx={16} fill="#fff" />
      </g>
      <rect
        x={-360}
        y={-16}
        width={400}
        height={56}
        rx={16}
        fill="#fff"
        stroke="#A1A5AD"
      />
      <path
        d="M5.055 3.245a.75.75 0 1 0-1.11 1.01l1.804 1.984c-3.405 2.09-4.87 5.31-4.934 5.457a.75.75 0 0 0 0 .61c.032.074.827 1.834 2.592 3.6C5.759 18.255 8.73 19.5 12 19.5a11.93 11.93 0 0 0 4.882-1.015l2.062 2.27a.75.75 0 1 0 1.11-1.01L5.055 3.245Zm4.437 7.11 3.907 4.299a3 3 0 0 1-3.907-4.299ZM12 18c-2.886 0-5.407-1.05-7.493-3.117A12.482 12.482 0 0 1 2.344 12c.44-.824 1.843-3.13 4.439-4.63L8.47 9.222a4.5 4.5 0 0 0 5.968 6.563l1.381 1.518A10.501 10.501 0 0 1 12 18Zm.563-8.947a.75.75 0 0 1 .28-1.473 4.515 4.515 0 0 1 3.635 3.997.75.75 0 0 1-.676.817.636.636 0 0 1-.07 0 .75.75 0 0 1-.75-.681 3.008 3.008 0 0 0-2.42-2.66Zm10.62 3.252c-.04.089-.99 2.191-3.128 4.107a.752.752 0 0 1-1.068-.05.75.75 0 0 1 .068-1.068A12.447 12.447 0 0 0 21.66 12a12.485 12.485 0 0 0-2.168-2.885C17.407 7.05 14.886 6 12 6c-.608 0-1.215.048-1.815.147a.75.75 0 1 1-.248-1.48A12.562 12.562 0 0 1 12 4.5c3.27 0 6.24 1.243 8.593 3.595 1.765 1.765 2.56 3.527 2.592 3.601a.75.75 0 0 1 0 .61h-.002Z"
        fill="#161616"
      />
      <defs>
        <filter
          id="a"
          x={-414}
          y={-350}
          width={512}
          height={704}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={8} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.0875 0 0 0 0 0.0875 0 0 0 0 0.0875 0 0 0 0.4 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_433_7848"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_433_7848"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function DashboardIcon(props) {
  return (
    <svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.29 7.866 9.79.788 9.782.781a1.307 1.307 0 0 0-1.766 0l-.008.007-7.5 7.078a1.312 1.312 0 0 0-.418.967V17.5a1.313 1.313 0 0 0 1.312 1.313h4.5A1.313 1.313 0 0 0 7.215 17.5V13a.188.188 0 0 1 .187-.188h3a.187.187 0 0 1 .188.188v4.5a1.312 1.312 0 0 0 1.312 1.313h4.5a1.312 1.312 0 0 0 1.313-1.313V8.833a1.314 1.314 0 0 0-.425-.967Zm-.7 9.634a.188.188 0 0 1-.188.188h-4.5a.188.188 0 0 1-.187-.188V13a1.312 1.312 0 0 0-1.313-1.313h-3A1.313 1.313 0 0 0 6.09 13v4.5a.188.188 0 0 1-.188.188h-4.5a.188.188 0 0 1-.187-.188V8.833a.188.188 0 0 1 .06-.139l.01-.007L8.778 1.61a.187.187 0 0 1 .246 0l7.495 7.077.008.008a.188.188 0 0 1 .062.139V17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LockIcon(props) {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.5 17.938h-7.438V12.25a10.063 10.063 0 0 0-20.124 0v5.688H10.5A3.063 3.063 0 0 0 7.437 21v24.5a3.062 3.062 0 0 0 3.063 3.063h35a3.063 3.063 0 0 0 3.063-3.063V21a3.063 3.063 0 0 0-3.063-3.063ZM20.562 12.25a7.438 7.438 0 0 1 14.875 0v5.688H20.563V12.25ZM45.938 45.5a.438.438 0 0 1-.437.438h-35a.438.438 0 0 1-.438-.438V21a.438.438 0 0 1 .438-.438h35a.438.438 0 0 1 .438.438v24.5ZM28 24.937a5.687 5.687 0 0 0-1.313 11.22v4.093a1.313 1.313 0 0 0 2.625 0v-4.093A5.687 5.687 0 0 0 28 24.937Zm0 8.75a3.062 3.062 0 1 1 0-6.124 3.062 3.062 0 0 1 0 6.125Z"
        fill="#303030"
      />
    </svg>
  );
}

export function BriefcaseIcon(props) {
  return (
    <svg
      width={32}
      height={33}
      viewBox="0 0 32 33"
      fill="#454FA2"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27 7.5h-5v-1a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v1H5a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2v-16a2 2 0 0 0-2-2Zm-15-1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v1h-8v-1Zm15 3v5.201A23 23 0 0 1 16 17.5a23.01 23.01 0 0 1-11-2.797V9.5h22Zm0 16H5v-8.545a25.031 25.031 0 0 0 22-.001V25.5Zm-14-11a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function FilterIcon(props) {
  return (
    <svg
      width={20}
      height={19}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4472 1.71941C19.3466 1.48584 19.1794 1.28711 18.9664 1.14805C18.7535 1.00899 18.5043 0.935763 18.25 0.937531H1.75C1.49591 0.938017 1.24743 1.01224 1.03471 1.1512C0.821991 1.29016 0.654177 1.48788 0.551638 1.72035C0.449098 1.95283 0.416241 2.21007 0.457055 2.46086C0.497869 2.71164 0.610599 2.94519 0.781563 3.13316L0.787188 3.13972L7.13688 9.91878C7.16947 9.9536 7.18757 9.99953 7.1875 10.0472V17.25C7.1876 17.4876 7.25217 17.7207 7.37433 17.9244C7.49649 18.1281 7.67165 18.2949 7.88114 18.4069C8.09063 18.5189 8.32659 18.572 8.56387 18.5604C8.80114 18.5489 9.03083 18.4731 9.22844 18.3413L12.2284 16.3416C12.4082 16.2217 12.5555 16.0593 12.6574 15.8687C12.7593 15.6782 12.8126 15.4655 12.8125 15.2494V10.0472C12.8124 9.99953 12.8305 9.9536 12.8631 9.91878L19.2184 3.13316C19.3913 2.94609 19.5052 2.71221 19.5459 2.46076C19.5866 2.20931 19.5523 1.95145 19.4472 1.71941ZM18.3916 2.37003L12.0419 9.15003C11.8136 9.39291 11.6868 9.71389 11.6875 10.0472V15.2494C11.6876 15.2804 11.68 15.3109 11.6655 15.3382C11.6509 15.3655 11.6298 15.3888 11.6041 15.406L8.60406 17.4057C8.57585 17.4245 8.54306 17.4353 8.50919 17.437C8.47532 17.4386 8.44163 17.4311 8.41171 17.4151C8.38179 17.3991 8.35676 17.3753 8.33929 17.3463C8.32182 17.3172 8.31256 17.2839 8.3125 17.25V10.0472C8.31278 9.71369 8.18607 9.39257 7.95813 9.14909L1.61219 2.37378C1.58856 2.34689 1.57312 2.3138 1.56769 2.27841C1.56227 2.24303 1.56709 2.20683 1.58158 2.1741C1.59608 2.14136 1.61964 2.11346 1.64948 2.09369C1.67933 2.07392 1.71421 2.06311 1.75 2.06253H18.25C18.2866 2.06124 18.3227 2.07125 18.3534 2.09122C18.3841 2.11118 18.4079 2.14012 18.4216 2.17409C18.437 2.20594 18.4425 2.24174 18.4371 2.27674C18.4318 2.31174 18.4159 2.34428 18.3916 2.37003Z"
        fill="currentcolor"
      />
    </svg>
  );
}
